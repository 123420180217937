<template>
  <div>
    <transition name="fade-three" mode="out-in">
      <router-view />
    </transition>
    <van-tabbar v-model="active" ruote z-index='1000' safe-area-inset-bottom :before-change="beforeChange">
      <van-tabbar-item replace :to="item.path" :name="item.routeName" v-for="item in tabBarArr" class="tab-item"
        :class="{ bigItem: item.routeName == $route.name }" :key="item.id">
        <template #icon="props">
          <img :src="props.active ? item.selected : item.normal" />
        </template>
        {{ item.name }}
      </van-tabbar-item>
    </van-tabbar>

    <van-popup class="codeboxpopup" v-model="cshow" round :style="{ width: '90%' }" :close-on-click-overlay="false">
      <div class="codebox">
        <img src="@/assets/img/tips@2x.png" alt="">
        <p class="t">请先绑定大区</p>
        <p>输入密码</p>
        <van-field v-model="pass" clearable placeholder="请输入大区密码" />
        <van-button block @click="bindTeam" :loading="bloading">确认</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: "tabbar",
    data() {
      return {
        show: true,
        tabBarArr: [
          {
            id: 0, //ID号
            name: "首页", //名称
            path: "/",
            routeName: "/", //路由名称
            normal: require("@/assets/img/shouye_zc@2x.png"), //未选中时图标
            selected: require("@/assets/img/shouye_xz@2x.png"), //选中时图标
          },
          {
            id: 1,
            name: "商城",
            path: "/shop",
            routeName: "shop",
            normal: require("@/assets/img/shangcheng_zc@2x.png"),
            selected: require("@/assets/img/shangcheng_xz@2x.png"),
          },
          {
            id: 2,
            name: "理财",
            path: "/wealth",
            routeName: "wealth",
            normal: require("@/assets/img/licai_zc@2x.png"),
            selected: require("@/assets/img/licai_xz@2x.png"),
          },
          {
            id: 3,
            name: "个人",
            path: "/mine",
            routeName: "mine",
            normal: require("@/assets/img/wode_zc@2x.png"),
            selected: require("@/assets/img/wode_xz@2x.png"),
          }
        ],
        isShowBottom: true, //显示或者隐藏footer
        documentHeight: document.documentElement.clientHeight, //默认屏幕高度

        cshow: false,
        pass: '',
        bloading: false,
      }
    },
    computed: {
      active: {
        get: function () {
          let obj = this.tabBarArr.find(val => val.routeName == this.$route.name)
          return obj.id || 0
        },
        set: function (data) { },
      }
    },
    methods: {
      beforeChange(name) {
        if (name === 'wealth') {
          const team_id = this.local.get('team_id')
          if (team_id == 0) {
            this.cshow = true
            return false
          } else {
            this.$router.replace('wealth')
          }
        } else {
          this.$router.replace(name)
        }
      },
      bindTeam() {
        if (!this.pass) return this.$toast('请输入大区密码')
        this.bloading = true
        this.$post({
          loading: true,
          url: "/member/bindTeam",
          data: { team_no: this.pass },
          success: (res) => {
            this.$toast({
              message: '绑定成功',
              onClose: () => {
                this.bloading = false
                this.cshow = false
                this.$router.replace('wealth')
              }
            })
          },
          tip: () => { this.bloading = false },
        });
      }
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          if (this.documentHeight > document.documentElement.clientHeight) {
            this.isShowBottom = false
          } else {
            this.isShowBottom = true
          }
        })()
      }
    },
  }
</script>
<style lang="less" scoped>
  .fade-three-enter-active,
  .fade-three-leave-active {
    transition: opacity 0.15s;
  }
  .fade-three-enter,
  .fade-three-leave-to
  /* .fade-three-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }
  /deep/.van-tabbar {
    .van-tabbar-item {
      .van-tabbar-item__text {
        color: @tab_in_color;
      }
    }
    .van-tabbar-item--active {
      .van-tabbar-item__text {
        color: @tab_ac_color;
      }
    }
  }
  :deep(.van-popup) {
    background: none;
    padding: 40px 20px 20px;
    img {
      width: 100px;
      height: 100px;
      margin-top: -65px;
    }
    .codebox {
      width: 100%;
      background: url('../assets/img/kuang@2x.png') no-repeat;
      background-size: 100% 100%;
      padding: 26px 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      p {
        width: 100%;
        color: #333333;
        font-size: 13px;
        font-weight: 550;
      }
      .t {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .van-field {
        width: 100%;
        margin: 10px 0 20px;
        background: #F8F8F8;
        border-radius: 3px;
      }
    }
  }
</style>
